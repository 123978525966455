import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FlightList = Loadable(lazy(() => import('./FlightList')));

const FlightRoutes = [
	{
		path: '/dashboard/flightlist',
		element: <FlightList />,
		auth: authRoles.admin,
	},
];

export default FlightRoutes;
