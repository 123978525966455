import { axiosInstance } from '../Axios';
import { storeData, toggleModal } from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: sessionStorage.getItem('loginInfo')
			? JSON.parse(sessionStorage.getItem('loginInfo'))
			: null,
		agent: null,
		product: null,
		addbanner: null,
		deletebanner: null,
		category: null,
		categorydelete: null,
		addcategory: null,
		createproduct: null,
		deleteproduct: null,
		addFund: null,
		debitFund: null,
		trans: null,
		search: null,
		bannerInfo: null,
		pdInfo: null,
		addpdInfo: null,
		partnermar: null,
		popdescdelete: null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agentRequest(state, action) {
			state.loading = true;
		},
		agentSuccess(state, action) {
			state.loading = false;
			state.agent = action.payload;
			state.error = null;
		},
		agentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productRequest(state, action) {
			state.loading = true;
		},
		productSuccess(state, action) {
			state.loading = false;
			state.product = action.payload;
			state.error = null;
		},
		productFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addBannerRequest(state, action) {
			state.loading = true;
		},
		addBannerSuccess(state, action) {
			state.loading = false;
			state.addbanner = action.payload;
			state.error = null;
		},
		addBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerdeleteRequest(state, action) {
			state.loading = true;
		},
		bannerdeleteSuccess(state, action) {
			state.loading = false;
			state.deletebanner = action.payload;
			state.error = null;
		},
		bannerdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categoryRequest(state, action) {
			state.loading = true;
		},
		categorySuccess(state, action) {
			state.loading = false;
			state.category = action.payload;
			state.error = null;
		},
		categoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		deletecategoryRequest(state, action) {
			state.loading = true;
		},
		deletecategorySuccess(state, action) {
			state.loading = false;
			state.categorydelete = action.payload;
			state.error = null;
		},
		deletecategoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		createcategoryRequest(state, action) {
			state.loading = true;
		},
		createcategorySuccess(state, action) {
			state.loading = false;
			state.addcategory = action.payload;
			state.error = null;
		},
		createcategoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		createproductRequest(state, action) {
			state.loading = true;
		},
		createproductSuccess(state, action) {
			state.loading = false;
			state.createproduct = action.payload;
			state.error = null;
		},
		createproductFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		deleteRequest(state, action) {
			state.loading = true;
		},
		deleteSuccess(state, action) {
			state.loading = false;
			state.deleteproduct = action.payload;
			state.error = null;
		},
		deleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addFundRequest(state, action) {
			state.loading = true;
		},
		addFundSuccess(state, action) {
			state.loading = false;
			state.addFund = action.payload;
			state.error = null;
		},
		addFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitFundRequest(state, action) {
			state.loading = true;
		},
		debitFundSuccess(state, action) {
			state.loading = false;
			state.debitFund = action.payload;
			state.error = null;
		},
		debitFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		transRequest(state, action) {
			state.loading = true;
		},
		transSuccess(state, action) {
			state.loading = false;
			state.trans = action.payload;
			state.error = null;
		},
		transFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		searchRequest(state, action) {
			state.loading = true;
		},
		searchSuccess(state, action) {
			state.loading = false;
			state.search = action.payload;
			state.error = null;
		},
		searchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.bannerInfo = action.payload;
			storeData('bannerInfo', action.payload);
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pdRequest(state, action) {
			state.loading = true;
		},
		pdSuccess(state, action) {
			state.loading = false;
			state.pdInfo = action.payload;
			storeData('pdInfo', action.payload);
			state.error = null;
		},
		pdFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addpdBannerRequest(state, action) {
			state.loading = true;
		},
		addpdBannerSuccess(state, action) {
			state.loading = false;
			state.addpdInfo = action.payload;
			storeData('addpdInfo', action.payload);
			state.error = null;
		},
		addpdBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		partnermarRequest(state, action) {
			state.loading = true;
		},
		partnermarSuccess(state, action) {
			state.loading = false;
			state.partnermar = action.payload;
			storeData('partnermar', action.payload);
			state.error = null;
		},
		partnermarFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		popdescdeleteRequest(state, action) {
			state.loading = true;
		},
		popdescdeleteSuccess(state, action) {
			state.loading = false;
			state.popdescdelete = action.payload;
			state.error = null;
		},
		popdescdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};
const config2 = {
	headers: {
		'Content-Type': 'multipart/form-data',
		Accept: 'application/json',
	},
};
export const {
	loginRequest,
	loginSuccess,
	loginFail,
	agentRequest,
	agentSuccess,
	agentFail,
	productRequest,
	productSuccess,
	productFail,
	addBannerRequest,
	addBannerSuccess,
	addBannerFail,
	bannerdeleteRequest,
	bannerdeleteSuccess,
	bannerdeleteFail,
	categoryRequest,
	categorySuccess,
	categoryFail,
	deletecategoryRequest,
	deletecategorySuccess,
	deletecategoryFail,
	createcategoryRequest,
	createcategorySuccess,
	createcategoryFail,
	createproductRequest,
	createproductSuccess,
	createproductFail,
	deleteRequest,
	deleteSuccess,
	deleteFail,
	addFundRequest,
	addFundSuccess,
	addFundFail,
	debitFundRequest,
	debitFundSuccess,
	debitFundFail,
	transRequest,
	transSuccess,
	transFail,
	searchRequest,
	searchSuccess,
	searchFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	pdRequest,
	pdSuccess,
	pdFail,
	addpdBannerRequest,
	addpdBannerSuccess,
	addpdBannerFail,
	partnermarRequest,
	partnermarSuccess,
	partnermarFail,
	popdescdeleteRequest,
	popdescdeleteSuccess,
	popdescdeleteFail,
} = actions;

export const loginDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(loginRequest());
			console.log('body', bodyData);

			const { data } = await axiosInstance.post(
				'/partner/login',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(loginSuccess(data));
			if (data) {
				navigate('/dashboard/default');
				setLoading(false);
				let form = {
					id: data.result.id,
				};
				dispatch(showagentDispatch(form));
				dispatch(showtransDispatch(form));
				dispatch(showsearchDispatch(form));
				// dispatch(showproductDispatch(data.token));
				// dispatch(showcategoryDispatch(data.token));
			}
		} catch (error) {
			setLoading(false);
			alert('Something went wrong');
			dispatch(
				loginFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showagentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(agentRequest());
		const { data } = await axiosInstance.post(
			'/partner/agents',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(agentSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			agentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showpartnermarDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(partnermarRequest());
		const { data } = await axiosInstance.post(
			'/partner/showpartnermarkup',
			bodyData,
			config
		);
		dispatch(partnermarSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			partnermarFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtransDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(transRequest());
		const { data } = await axiosInstance.post(
			'/partner/showtrans',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(transSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			transFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showsearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(searchRequest());
		const { data } = await axiosInstance.post(
			'/partner/showflightsearch',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(searchSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			searchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addFundAgentDispatch = (body, data) => async (dispatch) => {
	try {
		dispatch(toggleModal(false));
		dispatch(addFundRequest());
		const { data } = await axiosInstance.post(
			'/partner/addbalToAgent',
			body,
			config
		);
		console.log('data', data);
		dispatch(addFundSuccess(data));

		let form = {
			id: data.result.id,
		};
		dispatch(showagentDispatch(form));
	} catch (error) {
		console.log('error', error);
		dispatch(
			addFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const debitFundAgentDispatch = (body, data) => async (dispatch) => {
	try {
		dispatch(debitFundRequest());
		const { data } = await axiosInstance.post(
			'/partner/debitbalFromAgent',
			body,
			config
		);
		console.log('data', data);
		dispatch(debitFundSuccess(data));
		dispatch(toggleModal(false));
		let form = {
			id: data.result.id,
		};
		dispatch(showagentDispatch(form));
	} catch (error) {
		console.log('error', error);
		dispatch(
			debitFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const createbannerDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(addBannerRequest());
			const { data } = await axiosInstance.post(
				'/partner/addbanner',
				bodyData,
				config2
			);
			console.log('data', data);
			dispatch(addBannerSuccess(data));
			navigate('/dashboard/bannerlist');
		} catch (error) {
			dispatch(
				addBannerFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(bannerRequest());
		const { data } = await axiosInstance.get('/partner/banner', config);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const pdBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(pdRequest());
		const { data } = await axiosInstance.get('/partner/populardes', config);
		console.log('data', data);
		dispatch(pdSuccess(data));
	} catch (error) {
		dispatch(
			pdFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const pdbannerDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(addpdBannerRequest());
		const { data } = await axiosInstance.post(
			'/partner/addpopulardes',
			bodyData,
			config2
		);
		console.log('data', data);
		dispatch(addpdBannerSuccess(data));
		navigate('/dashboard/pslist');
	} catch (error) {
		dispatch(
			addpdBannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletebannerDispatch = (id) => async (dispatch) => {
	try {
		dispatch(bannerdeleteRequest());
		const { data } = await axiosInstance.delete(
			`/partner/bannerdelete/${id}`,
			config
		);
		console.log('data', data);
		if (data) {
			dispatch(showBannerDispatch());
		}
		dispatch(bannerdeleteSuccess(data));
	} catch (error) {
		dispatch(
			bannerdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletepopdescDispatch = (id) => async (dispatch) => {
	try {
		dispatch(popdescdeleteRequest());
		const { data } = await axiosInstance.delete(
			`/partner/popdescdelete/${id}`,
			config
		);
		console.log('data', data);
		if (data) {
			dispatch(pdBannerDispatch());
		}
		dispatch(popdescdeleteSuccess(data));
	} catch (error) {
		dispatch(
			popdescdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updatebannerDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		console.log('body', bodyData);

		const { data } = await axiosInstance.post('login', bodyData, config);
		console.log('data', data);
		dispatch(loginSuccess(data));
		// setLoading(false);
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const createproductDispatch =
	(bodyData, token, navigate) => async (dispatch) => {
		try {
			const configs = {
				headers: { Authorization: `Bearer ${token}` },
			};
			dispatch(createproductRequest());
			const { data } = await axiosInstance.post(
				'/admin/product_create',
				bodyData,
				configs
			);
			console.log('data', data);
			dispatch(createproductSuccess(data));
			if (data) {
				navigate('/dashboard/productlist');
			}
		} catch (error) {
			dispatch(
				createproductFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showproductDispatch = (token) => async (dispatch) => {
	try {
		const configs = {
			headers: { Authorization: `Bearer ${token}` },
		};
		dispatch(productRequest());
		const { data } = await axiosInstance.get('/admin/show_product', configs);
		console.log('data', data);
		dispatch(productSuccess(data));
	} catch (error) {
		dispatch(
			productFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const editproductDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		console.log('body', bodyData);

		const { data } = await axiosInstance.post('login', bodyData, config);
		console.log('data', data);
		dispatch(loginSuccess(data));
		// setLoading(false);
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deleteproductDispatch = (id, token) => async (dispatch) => {
	try {
		const configs = {
			headers: { Authorization: `Bearer ${token}` },
		};
		dispatch(deleteRequest());
		const { data } = await axiosInstance.get(
			`/admin/delete_product/${id}`,
			configs
		);
		console.log('data', data);
		dispatch(deleteSuccess(data));
		if (data) {
			dispatch(showproductDispatch(token));
		}
	} catch (error) {
		dispatch(
			deleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const createcategoryDispatch =
	(bodyData, token, navigate) => async (dispatch) => {
		try {
			const configs = {
				headers: { Authorization: `Bearer ${token}` },
			};
			dispatch(createcategoryRequest());
			const { data } = await axiosInstance.post(
				'/admin/categories',
				bodyData,
				configs
			);
			console.log('data', data);
			dispatch(createcategorySuccess(data));
			if (data) {
				navigate('/dashboard/categorylist');
				dispatch(showcategoryDispatch(token));
			}
		} catch (error) {
			dispatch(
				createcategoryFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showcategoryDispatch = (token) => async (dispatch) => {
	try {
		const configs = {
			headers: { Authorization: `Bearer ${token}` },
		};
		dispatch(categoryRequest());
		const { data } = await axiosInstance.get('/admin/categories', configs);
		console.log('data', data);
		dispatch(categorySuccess(data));
	} catch (error) {
		dispatch(
			categoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const editcategoryDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		console.log('body', bodyData);

		const { data } = await axiosInstance.post('login', bodyData, config);
		console.log('data', data);
		dispatch(loginSuccess(data));
		// setLoading(false);
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletecategoryDispatch = (id, token) => async (dispatch) => {
	try {
		const configs = {
			headers: { Authorization: `Bearer ${token}` },
		};
		dispatch(deletecategoryRequest());
		const { data } = await axiosInstance.delete(
			`/admin/categories/${id}`,
			configs
		);
		console.log('data', data);
		dispatch(deletecategorySuccess(data));
		if (data) {
			dispatch(showcategoryDispatch(token));
		}
	} catch (error) {
		dispatch(
			deletecategoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export default HomeReducer;
